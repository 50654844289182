<template>
  <div class="login-page bg">

    <div class="lang-icon" @click="toLang">
      <div class="lang-img">
        <img :src="langIcon" alt="" >
      </div>

      <div class="lang-list" v-show="showLang">
        <div class="list" v-for="(item, i) in langList" :class="item.key == lang ? 'active' : ''" :key="i" @click.stop="changeLang(item)">
          <img :src="item.icon" alt="" >
        </div>
      </div>
    </div>

    <div class="page-logo flex-column-center">
      <img :src="logoUrl" alt="">
      <span>Login to TikTok</span>
    </div>

    <div class="login-main">
      <div class="login-form">
        <div class="form-item ">
          <div class="input ">
            <input type="text" v-model="form.username"  @focus="onInputFocus" @blur="onInputBlur" :placeholder="i18n.t('login.labelPlaceholder1')"/>
          </div>
        </div>

        <div class="form-item ">
          <div class="input " >
            <input :type="showPassword ? 'text' : 'password'" v-model="form.password"   @focus="onInputFocus" @blur="onInputBlur" :placeholder="i18n.t('login.labelPlaceholder2')"/>
            <div class="adot" @click="showPassword = !showPassword">
              <img :src="eyeUrl" alt="" v-show="!showPassword">
              <img :src="eyeUrl2" alt="" v-show="showPassword">
            </div>
          </div>
        </div>

        <div class="form-item">
          <div class="info" @click="toKefu">{{i18n.t('login.forget')}}</div>
        </div>

        <div class="btn-box  flex-column-center">
          <Button type="info"  @click="submit">{{i18n.t('login.btn')}}</Button>
        </div>

      </div>
      <div class="bottom-desc" v-show="showBottomText">
        <span>{{i18n.t('login.account')}}</span>
        <span class="red" @click="toRegister">{{i18n.t('login.register')}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import {NavBar, Button, Icon, Checkbox, Dialog} from 'vant';
import { login } from '@/api/login'
import { setToken, setUser, setUserId } from '@/utils/auth'
import headerBar from '@/components/header'
const FingerprintJS = require('@fingerprintjs/fingerprintjs')

// Initialize the agent at application startup.
const fpPromise = FingerprintJS.load()
export default {
  components: {
    NavBar,
    headerBar,
    Button,
    Checkbox,
    Icon
  },
  data() {
    return {
      logoUrl: require('../../assets/imgs/icon-login.png'),
      eyeUrl: require('../../assets/imgs/icon-eye.png'),
      eyeUrl2: require('../../assets/imgs/icon-eye2.png'),
      kefuUrl: require('../../assets/imgs/icon-kefu3.png'),
      langList: [],
      showPassword: false,
      checked: false,
      langListBak: [
         {
          name: 'English',
          key: 'en',
          icon: require('../../assets/imgs/lang/en.png'),
        },
          // 哈萨克斯坦语
        {
          name: 'Русский',
          key: 'kk',
          icon: require('../../assets/imgs/lang/kk.png'),
        },
        //   西班牙语
        {
          name: 'Español',
          key: 'es',
          icon: require('../../assets/imgs/lang/es.png'),
        },
          // 法语
        {
          name: 'Français',
          key: 'fr',
          icon: require('../../assets/imgs/lang/fr.png'),
        },
          // 德语
        {
          name: 'Deutsch',
          key: 'de',
          icon: require('../../assets/imgs/lang/de.png'),
        },
          // 意大利语
        {
          name: 'Italiano',
          key: 'it',
          icon: require('../../assets/imgs/lang/it.png'),
        },
          // 葡萄牙语
        {
          name: 'Português',
          key: 'pt',
          icon: require('../../assets/imgs/lang/pt.png'),
        },
          // 俄语
        {
          name: 'Pусский',
          key: 'ru',
          icon: require('../../assets/imgs/lang/ru.png'),
        },
        //   土耳其语
        {
          name: 'Türkçe',
          key: 'tr',
          icon: require('../../assets/imgs/lang/tr.png'),
        },
          // 韩语
        {
          name: '한국어',
          key: 'ko',
          icon: require('../../assets/imgs/lang/ko.png'),
        },
          // 日语
        {
          name: '日本語',
          key: 'ja',
          icon: require('../../assets/imgs/lang/ja.png'),
        },
          // 越南语
        {
          name: 'Tiếng Việt',
          key: 'vi',
          icon: require('../../assets/imgs/lang/vi.png'),
        },
          // 印尼语
        {
          name: 'Bahasa Indonesia',
          key: 'id',
          icon: require('../../assets/imgs/lang/id.png'),
        },
          // 马来语
        {
          name: 'Bahasa Melayu',
          key: 'ms',
          icon: require('../../assets/imgs/lang/ms.jpeg'),
        },
          // 泰语
        {
          name: 'ภาษาไทย',
          key: 'th',
          icon: require('../../assets/imgs/lang/th.png'),
        },
          // 阿拉伯语
        {
          name: 'العربية',
          key: 'ar',
          icon: require('../../assets/imgs/lang/ar.png'),
        },
          // 希伯来语
        {
          name: 'עברית',
          key: 'he',
          icon: require('../../assets/imgs/lang/he.png'),
        },
          // 印地语
        {
          name: 'हिन्दी',
          key: 'hi',
          icon: require('../../assets/imgs/lang/hi.png'),
        },
        {
          name: '简体中文',
          key: 'zh-CN',
          icon: require('../../assets/imgs/lang/zh.png'),
        },
        {
          name: '繁体中文',
          key: 'zh-TW',
          icon: require('../../assets/imgs/lang/zh.png'),
        },
      ],
      lang: 'zh-CN',
      langIcon: require('../../assets/imgs/lang/zh.png'),
      showBottomText: true,
      form: {
        username: '',
        password: '',
        Fingerprint: '',
        code: ''
      },
      checked: false,
      initialHeight: window.innerHeight,
      showLang: false
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName
    },
    language() {
      return this.$store.state.language
    },
    visitor_service(){
      return this.$store.state.visitor_service
    }
  },
  watch: {
    language() {
      this.initLang()
    }
  },
  created() {
    if(this.$route.query.token !== undefined) {
      setToken(this.$route.query.token)
      setUser(this.$route.query.username)
      setUserId(this.$route.query.id)
      localStorage.setItem('username', this.$route.query.username)
      setTimeout(() => {
        this.$router.push({
          name: 'index'
        })
      }, 200)
    }
  },
  mounted() {
    if (this.language) {
      this.initLang()
    }
    this.langList = [].concat(this.langListBak)


    let username = localStorage.getItem('username')
    if (username) {
      this.form.username = username
      this.form.password = localStorage.getItem('password')
      this.checked = true
    }


  },
  methods: {
    onInputFocus() {
      // 输入框获得焦点时，监听软键盘弹出事件
      window.addEventListener('resize', this.adjustPageHeight);
      // 恢复底部文字显示
      this.showBottomText = false;
    },
    onInputBlur() {
      // 输入框失去焦点时，移除事件监听
      window.removeEventListener('resize', this.adjustPageHeight);
      // 恢复底部文字显示
      this.showBottomText = true;
    },
    adjustPageHeight() {
      // 获取当前页面高度
      const newHeight = window.innerHeight;

      // 如果当前页面高度小于初始高度，表示软键盘弹出
      if (newHeight < this.initialHeight) {
        // 调整页面高度，可以使用你喜欢的动画效果
        document.body.style.height = this.initialHeight + 'px';
        // 隐藏底部文字
        this.showBottomText = false;
      } else {
        // 恢复默认高度
        document.body.style.height = 'auto';
        // 恢复底部文字显示
        this.showBottomText = true;
      }
    },
    initLang() {
      this.langList = this.langListBak.filter(v => {
        return this.language.indexOf(v.key) > -1
      })
      this.lang = localStorage.getItem('locale') || 'en'
      let index = this.langList.findIndex(v => {
        return this.lang == v.key
      })
      if (index > -1) {
        this.langIcon = this.langList[index].icon
      }
    },
    toKefu(){
        this.$router.push({
            name: 'kefu'
        })
    },
    toRegister() {
      this.$router.push({
        name: 'register'
      })
    },
    submit() {
      if (!this.form.username) {
        this.$toast({
          message: this.i18n.t('login.labelPlaceholder1'),
          icon: 'warning-o'
        })
        return
      }
      if (!this.form.password) {
        this.$toast({
          message: this.i18n.t('login.labelPlaceholder2'),
          icon: 'warning-o'
        })
        return
      }
      let form = new FormData()
      form.append('user_name', this.form.username)
      form.append('password', this.form.password)
      form.append('token', true)
      login(form).then(res => {
        if (res.code == 0) {
          try {
            this.$toast.success(this.i18n.t('login.success'))
            setToken(res.data.token)
            setUser(this.form.username)
            setUserId(res.data.id)
            localStorage.setItem('username', this.form.username)
            if (this.checked) {
              localStorage.setItem('password', this.form.password)
            }
            // 跳转到首页
            this.$router.push({
              name: 'index'
            })
            window.location.href = '/#/index'
          } catch (e) {
            this.$toast({
              message: e.toString(),
              icon: 'warning-o'
            })
          }
        } else {
          this.$toast({
            message: res.msg,
            icon: 'warning-o'
          })
        }
      })
    },
    toLang() {
      this.showLang = !this.showLang
    },
    changeLang(data) {
      this.showLang = false
      this.i18n.locale = data.key
      localStorage.setItem('locale', data.key)
      this.langIcon = data.icon
      this.lang = data.key
    }
  }
}
</script>